<template>
  <div>
    <drawer />
    <v-container>
      <v-row class="hidden-xs-only">
        <v-bottom-navigation color="#50ab87" grow>
          <v-btn @click="retrieveEventos()">
            <span>Todos</span>
            <v-icon active>mdi-home</v-icon>
          </v-btn>

          <v-btn @click="FiltroCategorias('1')">
            <span>Arte</span>
            <v-icon>mdi-bank</v-icon>
          </v-btn>

          <v-btn @click="FiltroCategorias('2')">
            <span>Ciencia</span>
            <v-icon>mdi-head-lightbulb</v-icon>
          </v-btn>

          <v-btn @click="FiltroCategorias('3')">
            <span>Deporte</span>
            <v-icon>mdi-run-fast</v-icon>
          </v-btn>

          <v-btn @click="FiltroCategorias('4')">
            <span>Civismo</span>
            <v-icon>mdi-badge-account-horizontal</v-icon>
          </v-btn>

          <v-btn @click="FiltroCategorias('5')">
            <span>RSU</span>
            <v-icon>mdi-school</v-icon>
          </v-btn>

          <v-btn @click="FiltroCategorias('6')">
            <span>Cultura emprendedora</span>
            <v-icon>mdi-account-tie</v-icon>
          </v-btn>
        </v-bottom-navigation>
      </v-row>

      <v-row>
        <v-col>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="eventos"
            :single-select="singleSelect"
            :search="search"
            item-key="id"
            show-select
            class="elevation-8 overflow-auto"
            height="520px"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Eventos</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:no-data> Espere un momento! </template>
          </v-data-table>
          <!-- <v-row>
            <v-btn
              depressed
              elevation="2"
              @click="sendEvent(selected)"
            >Registro y Asistencia</v-btn>
          </v-row> -->
        </v-col>
        <v-col>
          <info-Evento :evento="selected[0]"></info-Evento>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import EventosDataService from "../../services/EventosDataService";
import infoEvento from "../Eventos/InfoEvento";
import drawer from "../Drawer/Drawer.vue";
import axios from "axios";

export default {
  name: "formacionIntegral",
  data() {
    return {
      eventos: [],
      singleSelect: true,
      selected: [
        {
          tituloEvento: "",
          unidadResponsable: "",
          descripcionEvento: "",
          eventoDedicadoA: "",
          fechaInicio: "",
          inicioEvento: "",
          finEvento: "",
          sede: "",
          cupo: "",
          descripcion: "",
          creditos: "",
          categorias: "",
          responsable: "",
        },
      ],
      singleExpand: true,
      expanded: [],
      search: "",
      headers: [
        { text: "Titulo de evento", value: "tituloEvento" },
        { text: "Unidad responsable", value: "unidadResponsable" },
        { text: "Fecha de evento", sortable: true, value: "fechaInicio" },
        { text: "Cupo", value: "cupo" },
        { text: "Creditos", value: "creditos" },
        { text: "Responsable", value: "responsable" },
      ],
    };
  },
  components: {
    infoEvento,
    drawer,
  },
  methods: {
    retrieveEventos() {
      this.getCicloActual()
      .then(() => {
        return axios.get(`https://fibackend.ujed.mx/eventos/?cve_ciclo=${this.cve_ciclo}`);
      })
      .then((response) => {
        this.eventos = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
    },

    getCicloActual() {
      return axios.get(`https://fibackend.ujed.mx/eventos/cicloActual/`)
      .then((response) => {
        this.cve_ciclo = response.data.valor;
      })
      .catch((error) => {
        console.error("error", error);
      });
    },

    FiltroCategorias(categoria) {
      EventosDataService.filtroCategoria(categoria)
        .then((response) => {
          this.eventos = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {},
  created() {
    this.retrieveEventos();
  },
};
</script>

<style></style>
